import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Message } from 'element-ui';
import Head from '@/components/head/index';
import { PopularizeStructueList, PopularizeStructueAdd, PopularizeStructueEdit, PopularizeStructueDelete, PopularizeStructue, PopularizeStructueRead } from '@/api/ceshi_xiugai/popularize';
export default {
  data: function data() {
    return {
      //系统教程
      tab: 0,
      loading: true,
      course: this.$route.query.course,
      //系统教程
      radio: 1,
      titled: '部门流量分配',
      dialogReceive: false,
      dialogAdd: false,
      value: '',
      tableData: [],
      start_time: '',
      end_time: '',
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      type: 0,
      setTime: '',
      form: {
        date: ''
      },
      formAdd: {
        date: ''
      },
      formLabelWidth: '120px',
      personLabel: [],
      structureLabel: [],
      num: '',
      number: '',
      id: '',
      title: '',
      editStatus: false,
      pickerOptions: {
        disabledDate: function disabledDate(v) {
          return v.getTime() < new Date().getTime() - 86400000;
        }
      }
    };
  },
  mounted: function mounted() {
    if (this.course != 1) {
      this.GetList(this.query.page, this.query.pagesize);
    } else {}
  },
  computed: {},
  components: {
    Head: Head
  },
  methods: {
    GetList: function GetList(page, pagesize) {
      var _this = this;
      this.loading = true;
      PopularizeStructueList({
        page: String(page),
        pagesize: String(pagesize),
        start_set_time: this.start_time,
        end_set_time: this.end_time
      }).then(function (respomse) {
        _this.loading = false;
        _this.tableData = respomse.data.list.data;
        _this.query.total = respomse.data.list.total;
      });
    },
    formatDate: function formatDate(row) {
      return row.set_time.split(' ')[0];
    },
    //时间戳查询
    DataTime: function DataTime() {
      var _this2 = this;
      if (this.setTime == '' || this.setTime == null) {
        this.start_time = '';
        this.end_time = '';
        this.$nextTick(function () {
          _this2.GetList(1, _this2.query.pagesize);
        });
      } else {
        var s_time = this.setTime[0].toString();
        this.start_time = s_time.substring(0, 10);
        var e_time = this.setTime[1].toString();
        this.end_time = e_time.substring(0, 10);
        this.$nextTick(function () {
          _this2.GetList(1, _this2.query.pagesize);
        });
      }
    },
    prevent: function prevent(e) {
      var keynum = window.event ? e.keyCode : e.which; //获取键盘码
      if (keynum == 189 || keynum == 190 || keynum == 109 || keynum == 110) {
        this.$message.warning('禁止输入小数以及负数');
        e.target.value = '';
      }
    },
    //分页
    handleCurrentChange: function handleCurrentChange(val) {
      var _this3 = this;
      this.query.page = val;
      this.$nextTick(function () {
        _this3.GetList(_this3.query.page, _this3.query.pagesize);
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      var _this4 = this;
      this.query.pagesize = val;
      this.$nextTick(function () {
        _this4.GetList(_this4.query.page, _this4.query.pagesize);
      });
    },
    detail: function detail(val) {
      var _this5 = this;
      this.dialogReceive = true;
      PopularizeStructueRead({
        id: val
      }).then(function (respomse) {
        var data = respomse.data;
        _this5.form.date = data.set_time;
        _this5.personLabel = data.list;
      });
    },
    add: function add() {
      this.title = '新增部门流量分配';
      this.editStatus = false;
      this.dialogAdd = true;
      // var now = new Date();
      // var year = now.getFullYear(); // 得到年份
      // var month = now.getMonth(); // 得到月份
      // var date = now.getDate(); // 得到日期
      // month = month + 1;
      // month = month.toString().padStart(2, '0');
      // date = date.toString().padStart(2, '0');
      // var defaultDate = `${year}-${month}-${date}`;
      // this.$set(this.formAdd, 'date', defaultDate);
      // let time = new Date(defaultDate).getTime().toString().substring(0, 10);
      // this.time = time
    },
    edit: function edit(id, number) {
      var _this6 = this;
      this.id = id;
      this.number = number;
      this.title = '编辑部门流量分配';
      this.editStatus = true;
      this.dialogAdd = true;
      PopularizeStructueRead({
        id: id
      }).then(function (respomse) {
        var data = respomse.data;
        _this6.formAdd.date = data.set_time;
        _this6.num = data.set_num;
        var name = 'name';
        for (var index in data.list) {
          data.list[index][name] = data.list[index]['structure_name'];
          data.list[index]['set_num'] = data.list[index]['set_num'].toString();
          delete data.list[index]['structure_name'];
        }
        _this6.structureLabel = data.list;
      });
    },
    deleteData: function deleteData(row) {
      var _this7 = this;
      this.$confirm('该数据删除后不可恢复，确认删除？', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        PopularizeStructueDelete({
          id: String(row.id)
        }).then(function (respomse) {
          _this7.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success',
            duration: 2000
          });
          _this7.GetList(1, _this7.query.pagesize);
        });
      }).catch(function () {
        _this7.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    dateChange: function dateChange(val) {
      var _this8 = this;
      var time = new Date(val).getTime().toString().substring(0, 10);
      PopularizeStructue({
        time: time
      }).then(function (res) {
        var data = res.data;
        _this8.structureLabel = data.st_list;
        _this8.num = data.count_num;
      }).catch(function () {});
    },
    allocationAdd: function allocationAdd() {
      var _this9 = this;
      var dateTime = new Date(this.formAdd.date).getTime().toString().substring(0, 10);
      var count = 0;
      for (var i = 0; i < this.structureLabel.length; i++) {
        if (!this.structureLabel[i].set_num || this.structureLabel[i].set_num == '') {
          Message.closeAll();
          count++;
          this.$message({
            message: '数据量不能为空！',
            type: 'warning'
          });
        }
      }
      if (count == 0) {
        if (this.number == 2) {
          PopularizeStructueEdit({
            id: this.id,
            structure_share_str: this.structureLabel
          }).then(function (res) {
            _this9.$notify({
              title: '成功',
              message: '编辑成功',
              type: 'success',
              duration: 2000
            });
            _this9.dialogAdd = false;
            _this9.num = 0;
            _this9.structureLabel = [];
            _this9.formAdd.date = '';
            _this9.$nextTick(function () {
              _this9.GetList(1, _this9.query.pagesize);
            });
          }).catch(function () {});
          return false;
        } else {
          PopularizeStructueAdd({
            set_time: dateTime,
            structure_share_str: this.structureLabel
          }).then(function (res) {
            _this9.$notify({
              title: '成功',
              message: '新增成功',
              type: 'success',
              duration: 2000
            });
            _this9.dialogAdd = false;
            _this9.num = 0;
            _this9.structureLabel = [];
            _this9.formAdd.date = '';
            _this9.$nextTick(function () {
              _this9.GetList(1, _this9.query.pagesize);
            });
          }).catch(function () {});
          return false;
        }
      }
    },
    cancel: function cancel() {
      this.num = 0;
      this.structureLabel = [];
      this.formAdd.date = '';
    }
  }
};