var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-container", { staticClass: "app-container" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "auto" } },
              [
                _c(
                  "el-row",
                  { staticStyle: { margin: "0px 0px" }, attrs: { gutter: 24 } },
                  [_c("Head", { attrs: { name: _vm.titled } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticStyle: { height: "40px", margin: "20px 0 10px 0" },
                    attrs: { gutter: 24 },
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 4, sm: 4, md: 4, lg: 10, xl: 6 } },
                      [
                        _vm._v(
                          "\n                    流量分配日期：\n                    "
                        ),
                        _c("el-date-picker", {
                          staticStyle: { width: "240px" },
                          attrs: {
                            type: "daterange",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            size: "small",
                            clearable: "",
                            "value-format": "timestamp",
                          },
                          model: {
                            value: _vm.setTime,
                            callback: function ($$v) {
                              _vm.setTime = $$v
                            },
                            expression: "setTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 4, sm: 4, md: 4, lg: 2, xl: 1 } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.DataTime },
                          },
                          [_vm._v("查询")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 4, sm: 4, md: 4, lg: 2, xl: 1 } },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["新增"],
                                expression: "['新增']",
                              },
                            ],
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.add()
                              },
                            },
                          },
                          [_vm._v("新增")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-main",
              [
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      ref: "tb",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData,
                        "tooltip-effect": "dark",
                        border: "",
                        "header-cell-style": {
                          background: "#F9F9F9",
                          color: "#222222",
                          textAlign: "center",
                        },
                        "cell-style": { textAlign: "center" },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "序号", width: "120" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        scope.$index +
                                          (_vm.query.page - 1) *
                                            _vm.query.pagesize +
                                          1
                                      ) + " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3093164208
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "部门名称" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(_vm._s(scope.row.structure_name)),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1075324816
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "流量分配日期",
                          prop: "set_time",
                          align: "center",
                          formatter: _vm.formatDate,
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "分配数据量" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { color: "#1890ff" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.detail(scope.row.id)
                                        },
                                      },
                                    },
                                    [_c("span", [_vm._v("数据量接收详情")])]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2257229918
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "创建时间",
                          prop: "create_time",
                          align: "center",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "更新时间",
                          prop: "update_time",
                          align: "center",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.is_directional == 1
                                    ? _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "permission",
                                              rawName: "v-permission",
                                              value: ["编辑"],
                                              expression: "['编辑']",
                                            },
                                          ],
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.edit(scope.row.id, 2)
                                            },
                                          },
                                        },
                                        [_vm._v("编辑")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.is_directional == 1
                                    ? _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "permission",
                                              rawName: "v-permission",
                                              value: ["删除"],
                                              expression: "['删除']",
                                            },
                                          ],
                                          attrs: {
                                            type: "danger",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteData(scope.row)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1751809836
                        ),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("pagination", {
                    attrs: {
                      "current-page": _vm.query.page,
                      totalPage: _vm.query.total,
                      pageSize: _vm.query.pagesize,
                    },
                    on: {
                      handleSizeChange: _vm.handleSizeChange,
                      handleCurrentChange: _vm.handleCurrentChange,
                    },
                  }),
                ],
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    attrs: {
                      title: "流量分配详情",
                      visible: _vm.dialogReceive,
                      width: "800px",
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.dialogReceive = $event
                      },
                    },
                  },
                  [
                    _c(
                      "el-form",
                      { attrs: { model: _vm.form } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "分配日期",
                              "label-width": _vm.formLabelWidth,
                            },
                          },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "date",
                                placeholder: "选择日期",
                                disabled: "",
                              },
                              model: {
                                value: _vm.form.date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "date", $$v)
                                },
                                expression: "form.date",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "分配组",
                              "label-width": _vm.formLabelWidth,
                            },
                          },
                          _vm._l(_vm.personLabel, function (item, key) {
                            return _c(
                              "div",
                              { key: key, staticClass: "personItem" },
                              [
                                _c("el-input", {
                                  attrs: { disabled: true, width: "250px" },
                                  model: {
                                    value: item.structure_name,
                                    callback: function ($$v) {
                                      _vm.$set(item, "structure_name", $$v)
                                    },
                                    expression: "item.structure_name",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "receiveNum" },
                                  [
                                    _c("span", [_vm._v("数据量")]),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      attrs: { disabled: true, width: "250px" },
                                      model: {
                                        value: item.set_num,
                                        callback: function ($$v) {
                                          _vm.$set(item, "set_num", $$v)
                                        },
                                        expression: "item.set_num",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function ($event) {
                                _vm.dialogReceive = false
                              },
                            },
                          },
                          [_vm._v("取 消")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                _vm.dialogReceive = false
                              },
                            },
                          },
                          [_vm._v("确 定")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    staticClass: "formAdd",
                    attrs: {
                      title: _vm.title,
                      visible: _vm.dialogAdd,
                      width: "800px",
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.dialogAdd = $event
                      },
                      close: _vm.cancel,
                    },
                  },
                  [
                    _c(
                      "el-form",
                      { attrs: { model: _vm.formAdd } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: {
                              color: "#85ce61",
                              padding: "0 55px",
                            },
                          },
                          [
                            _vm._v(
                              " 本部门可分配流量数据为 " +
                                _vm._s(_vm.num) +
                                " 条 "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "分配日期",
                              "label-width": _vm.formLabelWidth,
                            },
                          },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "date",
                                placeholder: "选择日期",
                                "picker-options": _vm.pickerOptions,
                                disabled: _vm.editStatus,
                              },
                              on: { change: _vm.dateChange },
                              model: {
                                value: _vm.formAdd.date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formAdd, "date", $$v)
                                },
                                expression: "formAdd.date",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "分配组",
                              "label-width": _vm.formLabelWidth,
                            },
                          },
                          _vm._l(_vm.structureLabel, function (item, key) {
                            return _c(
                              "div",
                              { key: key, staticClass: "personItem" },
                              [
                                _c("el-input", {
                                  attrs: { disabled: true, width: "250px" },
                                  model: {
                                    value: item.name,
                                    callback: function ($$v) {
                                      _vm.$set(item, "name", $$v)
                                    },
                                    expression: "item.name",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "receiveNum" },
                                  [
                                    _c("span", [_vm._v("数据量")]),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      attrs: { type: "number", width: "250px" },
                                      nativeOn: {
                                        mousewheel: function ($event) {
                                          $event.preventDefault()
                                        },
                                        keyup: function ($event) {
                                          return _vm.prevent($event)
                                        },
                                      },
                                      model: {
                                        value: _vm.structureLabel[key].set_num,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.structureLabel[key],
                                            "set_num",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "structureLabel[key].set_num",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function ($event) {
                                _vm.dialogAdd = false
                              },
                            },
                          },
                          [_vm._v("取 消")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.allocationAdd },
                          },
                          [_vm._v("确 定")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.titled, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }